<template>
  <!-- 公司logo -->
  <div class="logo">
    <img src="../assets/logo.png" alt="logo" />
  </div>
  <!-- 首页四个宫格使用nut-grid，功能为：1、九级地址填写。2、绑定设备。3、查看设备。4、客服帮助 -->
  <nut-grid :column-num="2" square clickable>
    <nut-grid-item text="请假审批" >
      <Order />
    </nut-grid-item>
    <nut-grid-item text="课后作业" >
      <Add />
    </nut-grid-item>
  </nut-grid>
  <nut-grid :column-num="2" square clickable>
    <nut-grid-item text="试听申请">
      <Setting />
    </nut-grid-item>
    <nut-grid-item text="客服帮助">
      <Service />
    </nut-grid-item>
  </nut-grid>
</template>

<script>
import { Home, Category, Find, Cart, My, Message, Order, Add, Setting, Service } from '@nutui/icons-vue';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Home, Category, Find, Cart, My, Message, Order, Add, Setting, Service },
  mounted() {
    // 获取my页面传过来的id
    this.axios.get('/user/my/').then((res) => {
      console.log(res);
      // 将id存储到sessionStorage中
      window.sessionStorage.setItem('id', res.data.ID)
    });
  },
  methods: {
    toDetail() {
      this.$router.push('/productDetail')
    }
  }
};
</script>

<style>
.logo {
  text-align: center;
  margin-top: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>